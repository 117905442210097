import React, {useEffect, useRef, useState} from 'react';
import {Button, List, message} from 'antd';
import {useApi} from '../ApiProvider';
import {io} from 'socket.io-client';
import {v4 as uuidv4} from 'uuid';
import ReactMarkdown from 'react-markdown';
import {
    DeleteOutlined,
    DoubleRightOutlined,
    FileDoneOutlined,
    PaperClipOutlined,
    SendOutlined
} from '@ant-design/icons';
import Search from "antd/es/input/Search";
import {ImportedFileDto} from "../../generated/api";

interface ChatProps {
    executionId: string | undefined;
    chatName: string | undefined;
    tabName: string | undefined;
}

export function InternalChat(props: ChatProps) {
    const [messages, setMessages] = useState<any[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const inputRef = useRef<any>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const socketRef = useRef<any>(null);
    const currentBotMessageIdRef = useRef<string | null>(null);
    const [disableInput, setDisabledInput] = useState(false);
    const [selectedSummarization, setSelectedSummarization] = useState<string>('')
    const [modalTitle, setModalTitle] = useState<string>('')
    const [openSummarizationDocModal, setOpenSummarizationDocModal] = useState<boolean>(false)
    const [importedFiles, setImportedFiles] = useState<ImportedFileDto[] | undefined>([])
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [processedFiles, setProcessedFiles] = useState<Set<string>>(new Set());


    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        async function fetchMessages() {
            if (props.executionId && props.tabName) {
                const previousChat = await api.getChatHistory(props.executionId, props.tabName);
                if (previousChat.data) {
                    const nativeFiles = previousChat.data.importedFile?.map(file => {
                        const blob = new Blob([file.rawData!], {type: file.type})
                        return new File([blob], file.fileName, {type: file.type})
                    })
                    if (nativeFiles) setSelectedFiles(nativeFiles);
                    const formattedMessages = previousChat.data.chatHistoryMessages?.filter(ms => ms.text).map((msg) => ({
                        id: uuidv4(),
                        user: msg.type === 'user' ? 'user' : 'bot',
                        message: msg.text, // Adjusted to match your data structure
                    }));
                    setMessages(formattedMessages ?? []);
                    if (previousChat.data.finished) setDisabledInput(true);
                } else {
                    setLoading(true)
                    await api.processUserMessageFromChat(props.executionId ?? '',
                        '',
                        props?.tabName,
                        uuidv4()
                    ).then(res => setLoading(false)).catch(err => setLoading(false));
                }

            }
        }

        fetchMessages();
    }, [props.executionId, props.tabName]);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    useEffect(() => {
        setDisabledInput(false)
        setMessages([])
        setTimeout(() => {
        }, 100)

        socketRef.current = io({transports: ['websocket', 'polling']});

        socketRef.current.on('internalChatToken', (data: string) => {
            const llmToken: { token: string; executionId: string; tabName: string; messageId: string } = JSON.parse(data);
            if (llmToken.executionId === props.executionId && llmToken.tabName === props.tabName) {
                if (llmToken.messageId !== currentBotMessageIdRef.current) {
                    // New bot message starting
                    currentBotMessageIdRef.current = llmToken.messageId;
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        {id: currentBotMessageIdRef.current, user: 'bot', message: llmToken.token},
                    ]);
                } else {
                    // Append tokens to the current bot message
                    setMessages((prevMessages) =>
                        prevMessages.map((msg) =>
                            msg.id === currentBotMessageIdRef.current
                                ? {...msg, message: llmToken.token}
                                : msg
                        )
                    );
                }
                scrollToBottom();
            }
        });


        return () => {
            socketRef.current.disconnect();
        };
    }, [props.executionId, props.tabName]);

    function openModal(summary: string, modalTitle: string) {
        if (summary === '') return;
        setSelectedSummarization(summary)
        setModalTitle(modalTitle)
        setOpenSummarizationDocModal(true);
    }


    const handleFileDelete = (index: number) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };

    function getSummaryForFile(file: File): string {
        if (!importedFiles) return '';
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name
        })
        return arr?.[0].summarizationText ?? ''
    }

    const handleFileOpen = (file: File) => {
        if (file.size == 0) console.log('0000')
        const url = URL.createObjectURL(file);
        window.open(url, "_blank");
    };

    function getExtractedTextForFile(file: File): string {
        if (!importedFiles) return '';
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name
        })
        return arr?.[0].extractedText ?? ''
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    const handleSendMessage = async () => {
        const newFilesToSend = selectedFiles.filter(file => !processedFiles.has(file.name));
        if (inputValue.trim() === '' && newFilesToSend.length == 0) return;

        setLoading(true);
        const userMessage = inputValue.trim();
        setInputValue('');

        setMessages((prevMessages) => [...prevMessages, {id: uuidv4(), user: 'user', message: userMessage}]);

        const newMessageId = uuidv4();
        console.log(newFilesToSend)
        try {
            await api.processUserMessageFromChat(
                props.executionId ?? '',
                userMessage,
                props?.tabName,
                newMessageId,
                newFilesToSend
            );
            setProcessedFiles(prev => new Set([...prev, ...newFilesToSend.map(file => file.name)]));
        } catch (error) {
            message.error('Failed to send files.');
        }
        setLoading(false);
    };

    const handleFileChange = (files: FileList | null) => {
        if (!files) return;

        const acceptedExtensions = [".pdf", ".mp3", ".xml", ".json", ".m4a", ".docx", ".txt"];
        const maxFileSize = 100 * 1024 * 1024; // 100 MB in bytes

        const newFiles = Array.from(files).filter((file) => {
            if (file.size > maxFileSize) {
                message.error(`File size is too large for: ${file.name}.`);
                return false;
            }
            if (!acceptedExtensions.includes(file.name.substring(file.name.lastIndexOf('.')).toLowerCase())) {
                message.error(`Unsupported file type for: ${file.name}.`);
                return false;
            }
            return true;
        });

        setSelectedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];

            newFiles.forEach((file) => {
                let fileName = file.name;
                const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
                let baseName = fileName.substring(0, fileName.lastIndexOf('.'));
                if (fileName.lastIndexOf('.') === -1) baseName = fileName; // Handle files with no extension

                let counter = 1;
                while (updatedFiles.some((existingFile) => existingFile.name === fileName)) {
                    fileName = `${baseName} (${counter})${fileExtension}`;
                    counter++;
                }
                updatedFiles.push(new File([file], fileName, {type: file.type}));
            });

            return updatedFiles;
        });
    };

    function isSummarizationForFile(file: File) {
        if (!importedFiles) return;
        if (!file) return;
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name
        })
        return arr && arr.length !== 0 && arr[0].summarizationText !== '';
    }

    const handleFinishConversation = async () => {
        await api.continueAgentsChain({executionId: props.executionId!, tabName: props.tabName!});
        setMessages((prevMessages) => [
            ...prevMessages,
            {id: uuidv4(), user: 'bot', message: 'Thanks! Your data was submitted successfully!'},
        ]);
    };

    if (!props.executionId) {
        return null;
    }

    function getFileNameForFile(fileName: string) {
        return fileName.includes(".") ? fileName.split('.')[0] : fileName;
    }

    return (
        <div style={{position: 'relative', display: 'flex', flexDirection: 'column', height: '100%', width: '100%',}}>
            <div
                ref={chatContainerRef}
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '16px',
                }}
            >
                <List
                    dataSource={messages}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                            style={{
                                justifyContent: item.user === 'user' ? 'flex-end' : 'flex-start',
                                border: 'none',
                                width: '100%', // Ensure List.Item takes full width

                            }}
                        >
                            <div
                                style={{
                                    maxWidth: '48%',
                                    backgroundColor: item.user === 'user' ? '#1890ff' : '#f1f1f1',
                                    color: item.user === 'user' ? '#fff' : '#000',
                                    padding: '8px 16px',
                                    borderRadius: '20px'
                                }}
                            >
                                <ReactMarkdown
                                    components={{
                                        pre: ({node, ...props}) => (
                                            <pre style={{whiteSpace: 'pre-line'}} {...props}  />
                                        )
                                    }}
                                >{item.message}</ReactMarkdown>
                            </div>
                        </List.Item>
                    )}
                />

            </div>
            <div
                style={{
                    bottom: '0',
                    left: '0',
                    right: '0',
                    padding: '8px',
                    backgroundColor: '#fff',
                    borderTop: '1px solid #f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative' // Make this div the relative parent for positioning
                }}
            >
                <Search
                    disabled={disableInput}
                    ref={inputRef}
                    placeholder="Type your message."
                    allowClear={false}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    enterButton={
                        <Button
                            loading={loading}
                            type={'primary'}
                            icon={<SendOutlined/>}
                            style={{
                                height: '48px',
                                width: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        />
                    }
                    size="large"
                    suffix={
                        <Button
                            onClick={() => fileInputRef.current?.click()}
                            style={{border: 'none'}}
                            icon={<PaperClipOutlined/>}
                        />
                    }
                    onSearch={handleSendMessage}
                    style={{flexGrow: 1}} // Let the input take up the available space
                />

                <Button
                    disabled={disableInput}
                    type="default"
                    onClick={handleFinishConversation}
                    style={{
                        position: 'absolute',
                        bottom: '70px',
                        right: '20px',
                        zIndex: 1000,
                    }}
                >
                    Finish Conversation <DoubleRightOutlined/>
                </Button>
            </div>
            <input
                type="file"
                onChange={(e) => {
                    handleFileChange(e.target.files)
                }}
                style={{display: 'none'}}
                ref={fileInputRef}
                accept=".pdf,.mp3,.xml,.json,.m4a,.docx,.txt"
                multiple // Allow selecting multiple files
            />

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    marginTop: '10px',
                }}
            >
                {selectedFiles.map((file, index) => (
                    <div
                        key={file.name}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            onClick={() => handleFileOpen(file)}
                            type="link"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            title={file.name}
                        >
                            <PaperClipOutlined/>
                            <span
                                style={{
                                    display: 'inline-block',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '150px',
                                    verticalAlign: 'middle',
                                }}
                            >
        {file.name}
    </span>
                        </Button>
                        {processedFiles.has(file.name) ? <></> : <DeleteOutlined className="delete-file-icon" size={1}
                                                                                 onClick={() => handleFileDelete(index)}
                                                                                 style={{color: '#fd4343'}}/>}
                        {isSummarizationForFile(file) ? (
                            <FileDoneOutlined
                                title={"Document summarization"}
                                style={{backgroundColor: 'yellow', marginLeft: '6px'}}
                                onClick={() => {
                                    openModal(getSummaryForFile(file), 'File information');
                                }}
                            />
                        ) : null}
                    </div>
                ))}
            </div>
        </div>

    );
}

export default InternalChat;
