import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';

interface MobileContextType {
    isMobile: boolean;
    setIsMobile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileContext = createContext<MobileContextType | null>(null);

export const useMobileContext = (): MobileContextType => {
    const context = useContext(MobileContext);
    if (context === null) {
        throw new Error('useMobileContext must be used within a MobileProvider');
    }
    return context;
};

export function MobileProvider(props: { children: ReactNode }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <MobileContext.Provider value={{isMobile, setIsMobile}}>
            {props.children}
        </MobileContext.Provider>
    );
}
