import React, {useEffect, useState} from 'react';
import {Button, InputNumber, message, Modal, Table} from 'antd';
import {useApi} from '../ApiProvider';
import {ColumnsType} from "antd/es/table";
import {AdminChatMenu} from "./AdminChatMenu";
import {useKeycloak} from "@react-keycloak/web";

interface UserAdminPanel {
    username: string
    id: string,
    firstName: string,
    email: string
}

interface DataType {
    id?: string;
    user?: UserAdminPanel;
    email?: string;
    firstName?: string;
    organization?: string;
    lastName?: string;
    registrationDate?: string;
    workflowName?: string;
    workflowContent?: string;
    isActive?: boolean;
    confirmed?: boolean
    // roles: string[];
}

export function AllUsersTable() {
    const api = useApi();
    const [tableData, setTableData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<any[]>([]);
    const [selectedUserForRefill, setSelectedUserForRefill] = useState<UserAdminPanel | null>(null);
    const [isRefillModalVisible, setIsRefillModalVisible] = useState<boolean>(false);
    const [amount, setAmount] = useState(0);
    const [showAdminChatMenu, setShowAdminChatMenu] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const keycloak = useKeycloak();

    const baseColumns = [
        {
            title: 'Username',
            dataIndex: 'user',
            key: 'user',
            sorter: (a: any, b: any) => a.username.localeCompare(b.username),
            render: (user: any) => <a onClick={() =>
                handleUserClick(user.id, user.username)}>{user.username}</a>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),

        },
        {
            title: 'Registration Date',
            dataIndex: 'registrationDate',
            key: 'registrationDate',
            sorter: (a: any, b: any) => new Date(a.registrationDate) > new Date(b.registrationDate),
        },
        {
            title: 'Last Activity',
            dataIndex: 'lastActivity',
            key: 'lastActivity',
        },
        {
            title: 'Total Runs',
            dataIndex: 'totalRuns',
            key: 'totalRuns',
        },
        {
            title: 'Total Tokens',
            dataIndex: 'totalTokens',
            key: 'totalTokens',
        },
    ];
    const refillColumns: ColumnsType<DataType> = [
        {
            title: 'Account balance',
            dataIndex: 'accountBalance',
            key: 'accountBalance',
        },
        {
            title: 'Refill balance',
            key: 'refillBalance',
            render: (_, record) => (
                <Button onClick={() => showRefillModal(record.user)}>Refill</Button>
            ),
        },
    ];

    useEffect(() => {
        if (keycloak.keycloak.realmAccess?.roles.includes('admin')) fetchData().then(() => {
        });
    }, []);

    function handleUserClick(userId: number, username: string) {
        setSelectedUserId(userId);
        setShowAdminChatMenu(true);
    }

    async function fetchData() {
        setIsLoading(true);
        try {
            const response = await api.getAllUsers();
            const rolesResp = await api.getRoles()
            const roles = rolesResp.data;
            const hasRefillRole = roles.includes('refill');
            if (hasRefillRole) {
                setColumns([...baseColumns, ...refillColumns]);
            } else {
                setColumns(baseColumns);
            }
            const formattedData = response.data.map(user => ({
                user: {
                    username: user.username,
                    id: user.id,
                    firstName: user.firstName,
                    email: user.email
                },
                key: user.id,
                username: user.username,
                email: user.email,
                registrationDate: user.registrationDate,
                lastActivity: user.lastLogin,
                totalRuns: user.totalRuns,
                accountBalance: user.accountBalance,
                totalTokens: user.totalTokens,
            }));
            setTableData(formattedData);
        } catch (error) {
            message.error('Failed to fetch users');
        } finally {
            setIsLoading(false);
        }
    }

    function handleAmountChange(value: number | null) {
        setAmount(value ?? 0);
    }

    useEffect(() => {
        if (selectedUserForRefill != null) setIsRefillModalVisible(true);
    }, [selectedUserForRefill])

    function showRefillModal(user: any) {
        if (user) setSelectedUserForRefill(user);
    }

    async function handleRefillConfirm(username: string | undefined, userId: string | undefined, amount: any) {
        if (!userId) {
            message.error('User id cant be null!')
            return;
        }
        try {
            await api.refillAccount({userId: Number.parseInt(userId), amount}).then(() => {
                message.success(`Account ${username} is refilled successfully for ${amount}`);
                fetchData()
            }).catch((e: any) => message.error("Error refilling account" + e))
        } catch (error) {
            message.error("Error refilling account");
        }
        setIsRefillModalVisible(false);
    }

    return <>
        {!showAdminChatMenu && (
            <Table
                pagination={false}
                dataSource={tableData}
                columns={columns}
                loading={isLoading}
            />)}
        {showAdminChatMenu && selectedUserId !== null && selectedUserId !== undefined && (
            <AdminChatMenu userId={selectedUserId} showMenu={setShowAdminChatMenu}/>
        )}
        {isRefillModalVisible && (
            <Modal
                title={`Refill Account for ${selectedUserForRefill?.username}`}
                open={isRefillModalVisible}
                onOk={() => handleRefillConfirm(selectedUserForRefill?.username, selectedUserForRefill?.id, amount)}
                onCancel={() => {
                    setSelectedUserForRefill(null);
                    setIsRefillModalVisible(false)
                }
                }
            >
                <p>Please, enter amount to add to {selectedUserForRefill?.username}?</p>
                <InputNumber min={0} precision={0} value={amount} onChange={handleAmountChange}/>
            </Modal>
        )}
    </>

}

export default AllUsersTable;
