import React, {useEffect, useState} from 'react';
import {Button, Col, Input, message, Row} from 'antd';
import {Question, QuestionState} from "../../generated/api";
import {useApi} from "../ApiProvider";
import {DoubleRightOutlined} from "@ant-design/icons";

interface QuestionModalProps {
    messageId?: string;
    tabName: string;
    setTabActive: (value: string) => void
}

export default function QuestionTab(props: QuestionModalProps) {
    const [questions, setQuestions] = useState<Question[] | undefined>(undefined);
    const [disableAnswers, setDisableAnswers] = useState<boolean>(false);
    const api = useApi();
    const [answers, setAnswers] = useState<Question[]>([]);
    const [tabActive, setTabActive] = useState<string>('');

    const handleOk = async () => {
        await api.saveQuestionsWithAnswersForMessage(props.messageId!, props.tabName, answers.map(answer1 => {
            return {
                answer: answer1.answer ?? '',
                id: answer1.id,
                text: answer1.text
            }
        })).then(r => {
        }).catch(e => {
            message.error(e?.response?.message)
        })

    }
    const handleInputChange = (answer: string, question: string, questionId?: number,) => {
        setAnswers(prev => {
            const existingIndex = prev.findIndex(ans => ans.id === questionId);
            if (existingIndex > -1) {
                const updatedAnswers = [...prev];
                updatedAnswers[existingIndex] = {
                    id: questionId,
                    answer: answer,
                    text: question,
                    tabName: props.tabName
                };
                return updatedAnswers;
            } else {
                return [...prev, {id: questionId, answer: answer, text: question}];
            }
        });
    };

    useEffect(() => {
        setDisableAnswers(false)
        setQuestions(undefined)
        setAnswers([])
        api.getMessageById(props.messageId!).then(message => {
            const questionsForTab = message.data.additionalQuestions?.filter(quest => quest.tabName == props.tabName) ?? []
            setQuestions([...questionsForTab ?? []]);
            setAnswers([...questionsForTab ?? []])
            props.setTabActive('questions');
            if (questionsForTab.length == 0 || questionsForTab.some(q => q.questionState == QuestionState.Answered)) setDisableAnswers(true)
            if (!message.data.additionalQuestions || message.data.additionalQuestions.length == 0) {
                setDisableAnswers(true)
                api.saveQuestionsWithAnswersForMessage(props.messageId!, props.tabName, message.data.additionalQuestions).then(r => {
                })
            }
        });
    }, [props.tabName]);

    return (
        <div style={{width: '100%'}}>
            <h2>Please answer the following questions</h2>
            <div>
                {questions?.filter((question) => {
                    if (disableAnswers) return question.answer
                    else return true
                }).map((item) => (
                    <div key={item.id}>
                        <Row style={{marginBottom: 10}}>
                            <Col span={24}><label>{item.text}</label></Col>
                        </Row>
                        <Row style={{marginBottom: 10}}>
                            <Col span={24}>
                                <Input
                                    disabled={disableAnswers}
                                    placeholder={item.answer ?? 'Answer'}
                                    value={answers.find(ans => ans.id === item.id)?.answer}
                                    onChange={e => handleInputChange(e.target.value, item.text, item.id)}
                                />
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
            <Row justify="end" align="middle" style={{marginTop: 20, paddingBottom: 20}}>
                {!disableAnswers ? <Col style={{marginRight: 20}}>
                    <a aria-disabled={disableAnswers} onClick={handleOk}>Skip <DoubleRightOutlined/></a>
                </Col> : <> </>}
                <Col>
                    <Button disabled={disableAnswers} type="primary" onClick={handleOk}>OK</Button>
                </Col>
            </Row>
        </div>
    );
};
