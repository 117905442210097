import React, {useEffect, useState} from 'react';
import {Spin} from 'antd';
import {useApi} from "../ApiProvider";
import {useNavigate} from "react-router-dom"; // Import Antd stylesheet

const DataLoader = () => {
    const [data, setData] = useState<string | null>(null);
    const api = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        api.callbackApp({
            params: {
                'code': params.get('code'),
                'state': params.get('state'),
                'username': params.get('username'),
            }
        }).then((resp) => {
            navigate('/' + resp.data + '?external=true');
        }).catch((err) => {
            console.log('err')
            console.log(err)
        })
    }, []);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
        }}>
            <Spin size="large" tip="We are fetching FHIR data.. Please wait.."
                  spinning={true}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '100vh',
                }}/>
            </Spin>
        </div>
    );
};

export default DataLoader;
