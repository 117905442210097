/* tslint:disable */
/* eslint-disable */
/**
 * ddx-alunahealth
 * # Authentication Guide  We use the Bearer Token authentication mechanism for secure access to our APIs. This guide details the process of obtaining the token and using it to access our endpoints.  ## Obtaining the Bearer Token  1. **Endpoint**:      Retrieve the token from the following endpoint:     ```http    POST https://ddx.openworkflows.com/realms/ddx/protocol/openid-connect/token    ```  2. **Request Headers**:       ```http    Content-Type: application/x-www-form-urlencoded    ```  3. **Request Body** (x-www-form-urlencoded):     ```plaintext    client_id=ddx-app    username=YOUR_USERNAME    password=YOUR_PASSWORD    grant_type=password    ```     Replace `YOUR_USERNAME` and `YOUR_PASSWORD` with your actual account credentials.  4. **Response**:      A successful JSON response includes the `access_token`. Store it securely and use it to authenticate the API requests.      ```json    {      \"access_token\": \"YOUR_ACCESS_TOKEN\",      \"expires_in\": 300,      \"refresh_expires_in\": 1800,      \"token_type\": \"Bearer\"    }    ```     ## Using the Bearer Token for API Access  With the Bearer Token in hand:   **Set Authorization Header**:      Include the token in the `Authorization` header for your HTTP requests:     ```http    Authorization: Bearer YOUR_ACCESS_TOKEN    ```     Replace `YOUR_ACCESS_TOKEN` with the token you obtained from the previous step. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}
