import {useApi} from "../ApiProvider";
import React, {useEffect, useState} from "react";
import {MessageHeaderDto} from "../../generated/api";
import {Button, Input, Layout, Menu, message, Modal} from "antd";
import {ReflexContainer, ReflexElement, ReflexSplitter} from "react-reflex";
import {CloseOutlined, MenuOutlined, QuestionOutlined, UserOutlined} from "@ant-design/icons";
import ChatInterface from "../ChatInterface";
import {useMobileContext} from "../share/MobileProvider";
import {v4 as uuidv4} from "uuid";

interface AdminChatMenuProps {
    userId: number;
    showMenu: (value: boolean) => void;
}

export function AdminChatMenu(props: AdminChatMenuProps) {
    const [selectedWorkflow] = useState("");
    const api = useApi();
    const graylogUrl = "https://ddx.alunahealth.com/logs/"
    const [activeChat, setActiveChat] = useState<{ executionId: string | undefined, isNew: boolean, workflow: undefined | string }>({
        executionId: undefined,
        isNew: false,
        workflow: undefined
    });
    const [chats, setChats] = useState<MessageHeaderDto[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [shareModalText, setShareModalText] = useState("");
    const [selectedChatIdToShare,] = useState<number | undefined>();
    const [showExampleCase] = useState(false);
    const {isMobile} = useMobileContext();
    const [updateHeaders, setUpdateHeaders] = useState<string>("");

    const [menuVisible, setMenuVisible] = useState(false);

    useEffect(() => {
        api.getMessageHeadersForUser(props.userId).then((res) => {
            setChats(res.data)
        });
    }, [api, props.userId, updateHeaders]);

    const shareChat = () => {
        if (selectedChatIdToShare)
            api.shareMessage({messageId: selectedChatIdToShare, username: inputText}).then(res => {
                message.success(res.data.message).then(() => {})
                setIsModalOpen(false)}
            ).catch(e => {
                setShareModalText(e.response?.data?.message)
            });
    };

    function openLogs(executionId: string) {
            const encodedId = encodeURIComponent(':' + executionId)
            const url = `${graylogUrl}search?q=executionId${encodedId}&rangetype=relative&relative=0`;
            window.open(url, '_blank');
    }

    return <>
        <Modal title="User's email to share with:" open={isModalOpen}
               afterOpenChange={() => {
                   setShareModalText('');
                   setInputText('')
               }}
               onOk={shareChat} onCancel={() => {
            setIsModalOpen(false);
            setInputText('');
        }}
               styles={{mask: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}}}>
            <p style={{color: 'red'}}>{shareModalText}</p>
            <Input placeholder="E-mail" value={inputText} onChange={e => setInputText(e.target.value)}/>
        </Modal>
        <Layout hasSider={true}>
            <ReflexContainer orientation={"vertical"}>
                {menuVisible || !isMobile ? (
                    <ReflexElement minSize={150} maxSize={600} size={window.innerWidth <= 850 ? 180 : 250}>
                        <Layout.Sider width={"100%"} style={{height: "100%", backgroundColor: "#238e9f"}}>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, width: "100%"}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    padding: 6,
                                    height: "100%",
                                    overflow: "auto"
                                }}>
                                    <Menu theme="dark" mode="inline" style={{backgroundColor: "#238e9f"}}
                                          selectedKeys={activeChat.executionId ? [activeChat.executionId.toString()] : ['new']}
                                          items={[

                                              ...chats.filter(m => !m.isShared).map(chat => ({
                                                  key: chat.executionId,
                                                  label: <div className={'list-item'}>
                                                      <span className={'title'}>{chat.input}</span>
                                                      <span className={'delete'}
                                                            onClick={() => openLogs(chat.executionId)}>
                                                                                                       <QuestionOutlined/></span>

                                                  </div>,
                                                  icon: <UserOutlined/>
                                              }))
                                              ,
                                          ]} onClick={e => {
                                    if (e.key === 'new') {
                                        setActiveChat({
                                            executionId: undefined,
                                            isNew: false,
                                            workflow: selectedWorkflow
                                        })
                                    } else {
                                        setActiveChat({executionId: e.key, isNew: false, workflow: undefined})
                                    }
                                }}/>
                                {chats.filter(m => m.isShared).length !== 0 && (
                                    <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: 6}}>
                                        <p style={{
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                            flex: 1,
                                            padding: 6,
                                            color: 'white'
                                        }}>
                                            Shared cases:
                                        </p>
                                        <Menu theme="dark" mode="inline"
                                              style={{backgroundColor: "#238e9f"}}
                                              selectedKeys={activeChat.executionId ? [activeChat.executionId.toString()] : ['new']}
                                              items={[
                                                  ...chats.filter(m => m.isShared).map(chat => ({
                                                      key: chat.id.toString() ?? "",
                                                      label: (
                                                          <div className={'list-item'}>
                                                              <span className={'title'}>{chat.input}</span>
                                                              <span className={'delete'}
                                                                    onClick={() => openLogs(chat.executionId)}>
                                                      <QuestionOutlined/></span>
                                                          </div>
                                                      ),
                                                      icon: <UserOutlined/>
                                                  })),
                                              ]} onClick={e => setActiveChat({
                                            executionId: e.key,
                                            isNew: false,
                                            workflow: undefined
                                        })}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Layout.Sider>
                </ReflexElement> ) : null }
                {menuVisible || !isMobile ? <ReflexSplitter/>  : null}
                <ReflexElement>
                    <Layout style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}}>
                        {isMobile ? (
                            <Button type="primary" shape="circle"
                                    onClick={() => setMenuVisible(!menuVisible)}
                                    icon={menuVisible ?<CloseOutlined /> : <MenuOutlined /> }/>) : null}
                        <Layout.Content>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>


                                <ChatInterface workflow={selectedWorkflow}
                                               activeChat={activeChat.executionId}
                                               showMenu={(b: boolean) => setMenuVisible(b)}
                                               deleteChat={(executionId: string) => {
                                                   setChats(oldChats => {
                                                       return oldChats.filter(ch => ch.executionId != executionId)
                                                   })
                                               }}
                                               setActiveChat={
                                                   executionId => setActiveChat({
                                                       executionId: executionId,
                                                       isNew: true,
                                                       workflow: selectedWorkflow,
                                                   })
                                               }
                                               adminProps={{
                                                   disableRun: true,
                                                   disableVoiceInput: true,
                                                   disableAttachFile: true
                                               }}
                                               exampleCase={showExampleCase}
                                               isNewChat={activeChat.isNew}
                                               saveNewChat={
                                                   message => {
                                                       setChats(oldChats => {
                                                           if (oldChats.filter(c => c.groupId === message.groupId).length === 0) {
                                                               return [message, ...oldChats.filter(x => x.id !== message.id)]
                                                           }
                                                           return oldChats
                                                       })
                                                       setActiveChat({
                                                           executionId: message.executionId,
                                                           isNew: true,
                                                           workflow: selectedWorkflow
                                                       })
                                                   }
                                               }
                                               updateHeaders={
                                                   () => {
                                                       setUpdateHeaders(uuidv4())
                                                   }
                                               }/>
                            </div>
                        </Layout.Content>
                    </Layout>
                </ReflexElement>
            </ReflexContainer>
        </Layout>
    </>
}
