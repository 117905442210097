import React from 'react';
import {Button, Card} from 'antd';
import {LogoutOutlined} from "@ant-design/icons";

function WaitingPage() {
    const waitingPageContainerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
    };

    const waitingCardStyle: React.CSSProperties = {
        textAlign: 'center',
        padding: '50px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginTop: '-150px'
};


    const companyLogoStyle: React.CSSProperties = {
        maxWidth: '200px',
        marginRight: '5px'
    };


    const waitingTextStyle: React.CSSProperties = {
        fontSize: '18px',
        color: 'rgba(0, 0, 0, 0.65)'
    };
    const topRightStyle: React.CSSProperties = {
        position: 'absolute',
        top: '10px',
        right: '10px'
    };

    const logoutButtonStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        marginRight: '10px'
    };
    const getAppFileName = () => {
        return '/' + process.env.REACT_APP_LOGO_FILE_NAME
    }

    return (
        <div style={waitingPageContainerStyle}>
            <div style={topRightStyle}>
                <Button
                    size={"large"}
                    style={logoutButtonStyle}
                    icon={<LogoutOutlined/>}
                    onClick={() => window.location.href = '/logout'}
                >
                    Logout
                </Button>
            </div>
            <Card style={waitingCardStyle}>
                <img src={getAppFileName()} alt="Logo" style={companyLogoStyle}/>
                <p style={waitingTextStyle}>
                    Your access is pending.
                    <br/>
                    You will be notified by email once your account is approved.
                </p>
            </Card>
        </div>
    );
}

export default WaitingPage;
