export class ExampleCase {
    static text : string  = "History: 30 y.o. Male patient presents with a 3 day history of sore throat, runny nose, fever up to 101, better with tylenol. No abdominal pain, vomiting, able to tolerate PO. No rashes.\n" +
        "Additional pertinent positives: strep contacts\n" +
        "Pertinent negatives: no trouble breathing, no chest pain, headache or abdominal pain, no rash, no gastrointestinal or genitourinary symptoms.\n" +
        "Review of systems reviewed and negative except as noted in History.\n" +
        "Past medical history: asthma\n" +
        "Medications: Proair, flovent\n" +
        "Allergies: penicillins\n" +
        "Last menstrual period/pregnant (if applicable): non-contributory\n" +
        "Exam: \n" +
        "Vitals signs: T 99.9, P 100, RR 18, Osat 99, BP 140/90\n" +
        "\n" +
        "Testing: strep, flu, covid pending\n" +
        "General: pleasant, in no acute distress, alert, with normal speech, mood, and affect. The thought process is congruent.\n" +
        "Face: symmetric, atraumatic. Eyes, nose, and lips are normal in appearance. No rash. No conjunctival erythema or discharge was noted. No sinus or TMJ tenderness. Slight nasal congestion, no obvious rhinorrhea.\n" +
        "Eyes: extraocular movements intact and without pain bilaterally. No conjunctival erythema, edema, or discharge. No periocular edema, erythema, wounds, or bruising. Normal vision was reported by the patient.\n" +
        "Pharynx: postnasal drip, erythematous pharynx, tonsils absent. No lesions, normal dentition. \n" +
        "Neck: supple, normal range of motion, no visible masses, shotty LAD BL\n" +
        "Respiratory: no respiratory distress, cough, or wheeze, CTA BL.. Normal voice.\n" +
        "Abdomen: nontender in all quadrants. No obvious distention. \n" +
        "Back: normal posture. \n" +
        "MS: normal posture and movement of extremities, normal gait.\n" +
        "\n" +
        "Skin: No rashes or lesions, normal color and turgor.\n"
}