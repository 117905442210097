import React from 'react';
import {Button, Result} from 'antd';

function NoAccessPage() {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}>
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary" href="/">Back to Chat</Button>}
            />
        </div>
    );
}

export default NoAccessPage;
