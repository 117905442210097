import {useApi} from "../ApiProvider";
import {useEffect, useState} from "react";
import 'swagger-ui-react/swagger-ui.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {RedocStandalone} from "redoc";

export function SwaggerExternalApi() {
    const api = useApi();
    const [swaggerConfig, setSwaggerConfig] = useState(undefined);

    useEffect(() => {
        api.getSwaggerConfig().then(re => {
            setSwaggerConfig(
                re.data);
        });
    }, [api])

    return (
        <div style = {{overflow: 'auto',width:"100%", maxWidth:'none'}}>
            <RedocStandalone spec={swaggerConfig} options = {{ theme: {
                    colors: { primary: { main: "blue" } },
                    typography: {
                        fontSize: "14px",
                        code: {
                            fontSize: "13px"
                        }
                    }
                }, nativeScrollbars: false}} />
        </div>

    );
}