import React, {useEffect, useState} from 'react';
import {Button, message, Table} from 'antd';
import {useApi} from '../ApiProvider';
import {ColumnsType} from "antd/es/table";

interface UserAdminPanel {
    username: string
    id: string,
    firstName: string,
    email: string
}

interface DataType {
    id?: string;
    user?: UserAdminPanel;
    email?: string;
    firstName?: string;
    organization?: string;
    lastName?: string;
    registrationDate?: string;
    workflowName?: string;
    workflowContent?: string;
    isActive?: boolean;
    confirmed?: boolean
    // roles: string[];
}

export function WaitingListTable() {
    const api = useApi();
    const [tableData, setTableData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchData().then(() => {
        });
    }, []);

    async function handleConfirmClick(user: UserAdminPanel | undefined) {
        try {
            if (!user) return;
            await api.giveAccessRoleToUser({id: user.id, email: user.email, firstName: user.firstName});
            setTableData(prev => {
                let newTableData = [...prev]
                newTableData.find(record => record.user?.id === user.id)!.confirmed = true;
                return newTableData
            })
            console.log(user.id)
        } catch (error) {
            message.error("Error confirming access");
        }
    }

    async function fetchData() {
        setIsLoading(true);
        try {
            const response = await api.getAllWaitingUsers();
            const formattedData = response.data.map((user: any) => ({
                user: {
                    username: user.username,
                    id: user.id,
                    firstName: user.firstName,
                    email: user.email
                },
                userName: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email ?? '',
                organization: user.organization,
                registrationDate: user.registrationDate ?? '',
                lastActivity: user.lastLogin ?? '',
            }));
            setTableData(formattedData);
        } catch (error) {
            message.error('Failed to fetch users');
        } finally {
            setIsLoading(false);
        }
    }


    const columns: ColumnsType<DataType> = [
        {
            title: 'Username',
            dataIndex: 'userName',
            key: 'userName'
        },
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
        },
        {
            title: 'Last activity',
            dataIndex: 'lastActivity',
            key: 'lastActivity',
        },
        {
            title: 'Access',
            key: 'access',
            render: (_, record) => {
                const isConfirmed = record.confirmed;
                return (
                    <Button
                        onClick={() => handleConfirmClick(record.user)}
                        style={{color: isConfirmed ? 'green' : 'blue'}}
                    >
                        {isConfirmed ? 'Confirmed' : 'Confirm'}
                    </Button>
                );
            }
        },
    ];

    return (
        <Table
            pagination={false}
            dataSource={tableData}
            columns={columns}
            loading={isLoading}
        />
    );
}

export default WaitingListTable;
