import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import {useApi} from "./ApiProvider";

interface NewCasePageProps {
    createNewChat: (workflow: string, exampleCase: boolean) => void
}

export default function NewCasePage(props: NewCasePageProps) {

    const useApi1 = useApi();
    const [workflows, setWorkflows] = useState<{ [p: string]: { description: string } }>({});

    useEffect(() => {

        async function fetchWorkflows() {
            try {
                const response = await useApi1.allWorkflowsWithDescription();
                const data = response.data;
                setWorkflows(data);
            } catch (error) {
                console.error('Error fetching workflows:', error);
            }
        }

        fetchWorkflows().then();
    }, []);
    const getAppFileName = () => {
        return '/' + process.env.REACT_APP_LOGO_FILE_NAME
    }

    return (
        <div className="new-case-page-container">
            <div style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <img src={getAppFileName()} alt="Company Logo" className="new-case-page-company-logo"/>
            </div>
            <div>
                <div className="new-case-page-buttons-group">
                    {Object.entries(workflows).map(([key, value]) => (
                        <div key={key} style={{display: 'flex', height: '100%', paddingBottom: '10px'}}>
                            <Button
                                className="new-case-page-custom-button"
                                onClick={() => props.createNewChat(key, false)}>
                                <div style={{flex: 1}}>
                                    <span className="new-case-page-bold-text">{key}</span>
                                    <br/>
                                    <span className="new-case-page-light-text">{value.description}</span>
                                </div>
                                <div>
                                    <Button style={{marginTop: 5}} onClick={(event) => {
                                        event.stopPropagation(); // This stops the click event from bubbling up
                                        props.createNewChat(key, true);
                                    }}>
                                        Example Case
                                    </Button>
                                </div>
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{
                color: 'grey',
                opacity: 0.9,
                fontSize: 'small',
                textAlign: 'center',
                paddingBottom: 15,
                paddingTop: 15
            }}>
                Information is provided for informational purposes only and should not replace professional judgment.
            </div>
        </div>
    );
};
