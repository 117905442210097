import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import {useApi} from "../ApiProvider";
import {UserDto} from "../../generated/api";
import {
    DollarCircleOutlined,
    FundProjectionScreenOutlined,
    LinkOutlined,
    LoginOutlined,
    MailOutlined,
    UserOutlined
} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text"; // Corrected import for Text

interface SupportModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (description: string) => void;
}

export function AccountDetails({isOpen, onClose, onSubmit}: SupportModalProps) {
    const api = useApi();
    const [description, setDescription] = useState("");
    const [user, setUser] = useState<UserDto | null>(null);

    useEffect(() => {
        api.fetchUserInfo().then(data => setUser(data.data))
    }, [])

    const handleOk = () => {
        onSubmit(description);
        setDescription('');
        onClose();
    };

    const handleCancel = () => {
        onClose();
        setDescription('');
    };

    return (
        <Modal
            title={<Title level={3}>Account Details</Title>}
            open={isOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                </Button>
            ]}
            style={{top: 20, padding: '24px'}}
        >
            <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                {/* Left Side: User Details */}
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <div><UserOutlined style={{marginRight: '8px'}}/><Text strong>Username:</Text> {user?.username}
                    </div>
                    <div><MailOutlined style={{marginRight: '8px'}}/><Text strong>Email:</Text> {user?.email}</div>
                    <div><LinkOutlined style={{marginRight: '8px'}}/><a href="https://www.openworkflows.org/contact"
                                                                        target="_blank" rel="noopener noreferrer">Get
                        Help</a></div>
                    <div><LinkOutlined style={{marginRight: '8px'}}/><a
                        href="https://ddx.openworkflows.org/realms/ddx/protocol/openid-connect/auth?client_id=ddx-app&redirect_uri=https://ddx.openworkflows.org/&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD">Reset
                        Password</a></div>
                    <Button style={{width: 170}} danger href="/logout"><LoginOutlined style={{marginRight: '4px'}}/>Log
                        Out</Button>

                </div>

                {/* Right Side: Account Details */}
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <div><DollarCircleOutlined style={{marginRight: '8px'}}/><Text strong>Account
                        Balance:</Text> {user?.accountBalance?.toFixed(2)}$
                    </div>
                    <div><FundProjectionScreenOutlined style={{marginRight: '8px'}}/><Text strong>Total
                        Runs:</Text> {user?.totalRuns}</div>
                </div>
            </div>
        </Modal>
    );
}
