import React, {useState} from 'react';
import {Modal} from 'antd';

interface SupportModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (description: string) => void;
    errorMessage: string;
}

export function SupportModal({isOpen, onClose, onSubmit, errorMessage}: SupportModalProps) {
    const [description, setDescription] = useState("");

    const handleOk = () => {
        onSubmit(description);
        setDescription('');
        onClose();
    };

    const handleCancel = () => {
        onClose();
        setDescription('');
    };

    return (
        <Modal
            title="Add a description about the case you'd like to share:"
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            styles={{mask: {backgroundColor: 'rgba(0, 0, 0, 0.5)'}}}
        >
            <p style={{color: 'red'}}>{errorMessage}</p>
            <textarea
                placeholder="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                style={{width: '100%', height: '150px', resize: 'none'}}
            ></textarea>
        </Modal>
    )
}


