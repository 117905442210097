import React, {ReactNode, useContext} from "react";
import {Configuration, DefaultApi} from "../generated/api";
import axios, {AxiosError} from "axios";
import Keycloak from "keycloak-js";
import {message} from "antd";

export const ApiContext = React.createContext<DefaultApi | null>(null)

function getAxiosInstance(keycloak: Keycloak) {
    const axiosInstance = axios.create()
    const ignoreErrorList = [400, 422, 404, 403]

    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers.Authorization=`Bearer ${keycloak.token}`
            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error)
        });

    axiosInstance.interceptors.response.use(
        res => res,
        error => {
            // noinspection TypeScriptUnresolvedVariable
            if (!error?.config?._noMessage && !ignoreErrorList.includes(error?.response?.status)) {
                // noinspection JSIgnoredPromiseFromCall
                message.error("Error connecting to server " + error?.response?.message)
                console.error(error)
            }
            return Promise.reject(error)
        }
    )
    return axiosInstance;
}

export function ApiProvider(props: { keycloak: Keycloak, children: ReactNode}) {
    function getApi(keycloak: Keycloak) {
        const axiosInstance = getAxiosInstance(keycloak);
        const configuration = new Configuration({})
        return new DefaultApi(configuration, "", axiosInstance)
    }

    return <ApiContext.Provider value={getApi(props.keycloak)}>
        {props.children}
    </ApiContext.Provider>
}

export function useApi() {
    const context = useContext(ApiContext)
    if (context === null) throw new Error("Api is not initialized")
    return context
}
