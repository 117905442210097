import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Input, message, Modal, Table} from 'antd';
import {useApi} from '../ApiProvider';
import {ColumnsType} from "antd/es/table";
import {WorkflowConfigDto} from "../../generated/api";
import yaml from "js-yaml";
import {WorkflowEditor} from "../workflow/WorkflowEditor";
import {CheckboxChangeEvent} from "antd/es/checkbox";

interface UserAdminPanel {
    username: string
    id: string,
    firstName: string,
    email: string
}

interface DataType {
    id?: string;
    user?: UserAdminPanel;
    email?: string;
    firstName?: string;
    organization?: string;
    lastName?: string;
    registrationDate?: string;
    workflowName?: string;
    workflowContent?: string;
    active?: boolean;
    public?: boolean;
    confirmed?: boolean
    // roles: string[];
}

export function WorkflowEditorTable() {
    const api = useApi();
    const [tableData, setTableData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showGivePermissionModal, setShowGivePermissionModal] = useState<boolean>(false);
    const [workflowToEdit, setWorkflowToEdit] = useState<string | undefined>(undefined);
    const [showWorkflowEditor, setShowWorkflowEditor] = useState<boolean>(false);
    const [selectedWorkflowForGrantPermission, setSelectedWorkflowForGrantPermission] = useState<string | undefined>(undefined);
    const [grantPermissionUserEmail, setGrantPermissionUserEmail] = useState<string>('');

    const [, setCheckCloseWorkflowEditor] = useState<boolean>(false);

    useEffect(() => {

        fetchData().then(() => {
        });
    }, []);


    async function handleActiveChange(e: CheckboxChangeEvent, record: DataType) {
        const isChecked = e.target.checked;
        try {
            api.updateWorkflowStatus({name: record.workflowName!, active: isChecked}).then(() => {
                fetchData();
                message.success(`Workflow ${isChecked ? 'activated' : 'deactivated'} successfully`);
            }).catch(er => {
                message.error(er.response.data)
            });
        } catch (error) {
            message.error('Error updating user status');
        }
    }

    async function handlePublicChange(e: CheckboxChangeEvent, record: DataType) {
        const isChecked = e.target.checked;
        try {
            api.updateWorkflowPublicStatus({name: record.workflowName!, public: isChecked}).then(() => {
                fetchData();
                message.success(`Workflow now is ${isChecked ? 'public' : 'private'}`);
            }).catch(er => {
                message.error(er.response.data)
            });
        } catch (error) {
            message.error('Error updating workflow status');
        }
    }

    function handleEmailChange(value: string) {
        setGrantPermissionUserEmail(value);
    }

    async function fetchData() {
        setIsLoading(true);
        try {
            await api.allWorkflowsConfigs().then(resp => {
                const workflow: { [key: string]: WorkflowConfigDto; } = resp.data
                setTableData(Object.keys(workflow).map(key => ({
                    workflowName: workflow[key].name,
                    workflowContent: yaml.dump(workflow[key]),
                    active: workflow[key].active,
                    public: workflow[key].public,
                })))
            })
        } catch (error) {
            message.error('Failed to fetch workflows');
        } finally {
            setIsLoading(false);
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Workflow Name',
            key: 'workflowName',
            render: (workflow: DataType) => <a onClick={() => {
                setWorkflowToEdit(workflow.workflowContent)
                setShowWorkflowEditor(true);
            }}>{workflow.workflowName}</a>
        },
        {
            title: 'Active',
            key: 'active',
            render: (_, record) => (
                <Checkbox
                    checked={record.active}
                    onChange={(e) => handleActiveChange(e, record)}
                />
            ),
        },
        {
            title: 'Public',
            key: 'public',
            render: (_, record) => (
                <Checkbox
                    checked={record.public}
                    onChange={(e) => handlePublicChange(e, record)}
                />
            ),
        },
        {
            title: 'Grant permission',
            key: 'public',
            render: (_, record) => (
                <Button
                    onClick={() => {
                        setSelectedWorkflowForGrantPermission(record.workflowName);
                        setShowGivePermissionModal(true);
                    }}
                >
                    Grant
                </Button>
            ),
        },
    ]

    function handleGrantPermissionConfirm(selectedWorkflowForGrantPermission: string | undefined, grantPermissionUserEmail: string) {
        if (!selectedWorkflowForGrantPermission) {
            void message.error('Please, select workflow to share')
            return
        }
        api.setPermissionToWorkflow({
            userEmail: grantPermissionUserEmail,
            workflowName: selectedWorkflowForGrantPermission
        })
            .then(() => {
                message.success('Permission successfully granted to user : ' + grantPermissionUserEmail)
                setGrantPermissionUserEmail('')
                setShowGivePermissionModal(false);
            })
            .catch(er => {
                message.error('Error while giving permission: ' + er.response.data)
            })
    }

    return <>
        <Modal
            title={`Enter user's email to grant permission to workflow`}
            open={showGivePermissionModal}
            onOk={() => handleGrantPermissionConfirm(selectedWorkflowForGrantPermission, grantPermissionUserEmail)}
            onCancel={() => {
                setGrantPermissionUserEmail('')
                setSelectedWorkflowForGrantPermission(undefined)
                setShowGivePermissionModal(false)
            }
            }
        >
            <p>Please, enter email of user to grant permission to workflow</p>
            <Input placeholder={"Email"} value={grantPermissionUserEmail}
                   onChange={e => handleEmailChange(e.target.value)}/>
        </Modal>
        {!showWorkflowEditor && <Table
            pagination={false}
            dataSource={tableData}
            columns={columns}
            loading={isLoading}
        />}
        {!showWorkflowEditor && <Button
            type={'primary'}
            style={{
                display: 'flex',
                alignSelf: 'flex-start'
            }}
            onClick={() => {
                setWorkflowToEdit(undefined)
                setShowWorkflowEditor(true)

            }}>Add New </Button>}
        {showWorkflowEditor && (
            <WorkflowEditor contentToEdit={workflowToEdit} checkClose={setCheckCloseWorkflowEditor}
                            hideEditor={() => {
                                fetchData().then(() => {
                                });
                                setShowWorkflowEditor(false)
                            }}/>
        )}</>


}

export default WorkflowEditorTable;
